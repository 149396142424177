<template>
  <surround-box>
    <div class="outer-box">
      <div
        v-for="(item, index) in catList"
        :key="index"
        :class="['group-item', indexList.has(item.groupId) ? 'active' : '']"
        @click="changeGroup(item, index)"
      >
        <img
          style="width: 20px; height: 20px; margin-right: 10px"
          :src="item.servicePath + item.img"
          alt=""
        />
        <span> {{ item.groupName }}</span>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox";
export default {
  components: { surroundBox },
  name: "",
  data() {
    return {
      catList: [],
      indexList: new Set(),
    };
  },
  methods: {
    getCat() {
      this.$post(this.$api.REGION_MAP.STATICS).then((res) => {
        this.catList = res;
      });
    },
    changeGroup(item) {
      if (this.indexList.has(item.groupId)) {
        this.indexList.delete(item.groupId);
      } else {
        this.indexList.add(item.groupId);
      }
      // 添加this.$forceUpdate();
      // 进行强制渲染，效果实现。因为数据层次太多，render函数没有自动更新，需手动强制刷新。
      // 调用强制更新方法this.$forceUpdate()会更新视图和数据，触发updated生命周期
      this.$forceUpdate();
      let temp = [];
      for (let val of this.indexList.values()) {
        temp.push(val);
      }
      this.$emit("change-group", temp);
    },
  },
  mounted() {
    this.getCat();
  },
};
</script>

<style lang="less" scoped>
.outer-box {
  display: flex;
  padding: 10px;
  .group-item {
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    color: #fff;
  }
  .active {
    color: #00fff7;
  }
}
</style>