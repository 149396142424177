<template>
  <div class="surround-box">
    <span class="left-top surround-box-left-top"></span>
    <span class="left-bottom surround-box-left-bottom"></span>
    <span class="right-top surround-box-right-top"></span>
    <span class="right-bottom surround-box-right-bottom"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>